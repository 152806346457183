import { Record } from "immutable";
import { GET_INSTANCE } from "../dashboard/actions";
import {
  DELETE_INSTANCE,
  GET_COMMENTS,
  GET_INSTANCE_APPROVED,
  GET_INSTANCE_PENDING,
  GET_INSTANCE_REJECTED,
  RESET_STATE_AGENT,
  SUBMIT_INSTANCE,
  UPLOAD_CONTENT,
  UPLOAD_CONTENT_PROGRESS,
} from "./actions";

const ObjectRecord = new Record({
  isUploading: false,
  isSuccessUpload: false,
  progressTotal: 0,
  isSubmitToReviewSuccess: false,
  isDeleteSuccess: false,
  isLoading: false,
  pendingInstances: [],
  rejectInstances: [],
  approvedInstances: [],
  comments: [],
});
const initialState = new ObjectRecord();
const agent = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_CONTENT.REQUEST:
      return state.set("isUploading", true).set("isSuccessUpload", false);
    case UPLOAD_CONTENT.SUCCESS:
      return state.set("isSuccessUpload", true).set("isUploading", false);
    case UPLOAD_CONTENT.FAILURE:
      return state.set("isUploading", false).set("isSuccessUpload", false);
    case UPLOAD_CONTENT_PROGRESS.ADD:
      return state.set("progressTotal", action.progress);
    case SUBMIT_INSTANCE.REQUEST:
      return state.set("isLoading", true).set("isSubmitToReviewSuccess", false);
    case SUBMIT_INSTANCE.SUCCESS:
      return state.set("isSubmitToReviewSuccess", true).set("isLoading", false);
    case SUBMIT_INSTANCE.FAILURE:
      return state
        .set("isLoading", false)
        .set("isSubmitToReviewSuccess", false);
    case DELETE_INSTANCE.REQUEST:
      return state.set("isLoading", true).set("isDeleteSuccess", false);
    case DELETE_INSTANCE.SUCCESS:
      return state.set("isDeleteSuccess", true).set("isLoading", false);
    case DELETE_INSTANCE.FAILURE:
      return state.set("isLoading", false).set("isDeleteSuccess", false);
    case GET_INSTANCE_PENDING.REQUEST:
      return state.set("isLoading", true);
    case GET_INSTANCE_PENDING.SUCCESS:
      return state
        .set("pendingInstances", action?.response?.payload)
        .set("isLoading", false);
    case GET_INSTANCE_PENDING.FAILURE:
      return state.set("isLoading", false);
    case GET_INSTANCE_REJECTED.REQUEST:
      return state.set("isLoading", true);
    case GET_INSTANCE_REJECTED.SUCCESS:
      return state
        .set("rejectInstances", action?.response?.payload)
        .set("isLoading", false);
    case GET_INSTANCE_REJECTED.FAILURE:
      return state.set("isLoading", false);
    case GET_INSTANCE_APPROVED.REQUEST:
      return state.set("isLoading", true);
    case GET_INSTANCE_APPROVED.SUCCESS:
      return state
        .set("approvedInstances", action?.response?.payload)
        .set("isLoading", false);
    case GET_INSTANCE_APPROVED.FAILURE:
      return state.set("isLoading", false);
    case GET_INSTANCE.REQUEST:
      return state
        .set("isSubmitToReviewSuccess", false)
        .set("isDeleteSuccess", false);
    case RESET_STATE_AGENT.REQUEST:
      return state
        .set("isUploading", false)
        .set("isSuccessUpload", false)
        .set("isSubmitToReviewSuccess", false)
        .set("isDeleteSuccess", false);
    case GET_COMMENTS.REQUEST:
      return state.set("isLoading", true);
    case GET_COMMENTS.SUCCESS:
      return state
        .set("comments", action?.response?.payload)
        .set("isLoading", false);
    case GET_COMMENTS.FAILURE:
      return state.set("isLoading", false);
    default:
      return state;
  }
};
export default agent;
