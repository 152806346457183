import { Record } from "immutable";
import { SIGN } from "./actions";

const ObjectRecord = new Record({
  loading: false,
  loginSuccess: false,
  user: null,
});

const initialState = new ObjectRecord();

const auth = (state = initialState, action) => {
  switch (action.type) {
    case SIGN.REQUEST:
      return state.set("loading", true);
    case SIGN.SUCCESS:
      return state.set("loginSuccess", true).set("loading", false).set("user",action?.response?.payload);
    case SIGN.FAILURE:
      return state.set("loading", false);
    default:
      return state;
  }
};

export default auth;
