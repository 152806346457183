import { call, put, takeEvery, take, fork} from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import { getComments, postRequestComment, submitToReview, uploadMedia } from '../../services/agent';
import { displayNotification } from '../../utils/notificationHandler';
import { DELETE_INSTANCE, GET_COMMENTS, GET_INSTANCE_APPROVED, GET_INSTANCE_PENDING, GET_INSTANCE_REJECTED, POST_COMMENTS, SUBMIT_INSTANCE, uploadFilesProgress, UPLOAD_CONTENT } from './actions';
import { fetchInstances } from '../dashboard/actions';
import { deleteInstance, getContentOnApproved, getContentOnProcess, getContentOnRejected } from '../../services/dashboard';


function upload(payload, onProgress, isResubmit) {
  return uploadMedia(payload,onProgress,isResubmit);
}

function createUploader(files,isResubmit) {
  let emit;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const uploadProgressCb = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total);
    emit(percentage);
    if (percentage === 100) emit(END);
  };
  const uploadPromise = upload(files, uploadProgressCb, isResubmit);
  return [uploadPromise, chan];
}

function* watchOnProgress(chan) {
  while (true) { // eslint-disable-line no-constant-condition
    const progress = yield take(chan);
    yield put(uploadFilesProgress(progress));
  }
}


export function* handleFRequestUploadContent(action) {
    try {
      const [uploadPromise, chan] = yield call(createUploader, action.formData, action.isResubmit);
      yield fork(watchOnProgress, chan);
      const response = yield call(() => uploadPromise);
      yield put({
        type: UPLOAD_CONTENT.SUCCESS,
        response,
      });
    } catch (error) {
      displayNotification('error',error?.data?.msg,error?.data?.payload)
      yield put({type: UPLOAD_CONTENT.FAILURE, error});
    }
}
  
export function* watchFRequestUploadContent() {
   yield takeEvery(UPLOAD_CONTENT.REQUEST, handleFRequestUploadContent);
}



export function* handleRequestSubmitInstance(action) {
  try {
    const response = yield call(submitToReview, action.instanceID);
    yield put({
      type: SUBMIT_INSTANCE.SUCCESS,
      response,
    });
    yield put(fetchInstances());
  } catch (error) {
    displayNotification('error',null,error?.data?.msg)
    yield put({type: SUBMIT_INSTANCE.FAILURE, error});
  }
}

export function* watchRequestSubmitInstance() {
 yield takeEvery(SUBMIT_INSTANCE.REQUEST, handleRequestSubmitInstance);
}


export function* handleRequestDeleteInstance(action) {
  try {
    const response = yield call(deleteInstance, action.id);
    yield put({
      type: DELETE_INSTANCE.SUCCESS,
      response,
    });
    yield put(fetchInstances());
  } catch (error) {
    displayNotification('error',null,error?.data?.msg)
    yield put({type: DELETE_INSTANCE.FAILURE, error});
  }
}

export function* watchRequestDeleteInstance() {
 yield takeEvery(DELETE_INSTANCE.REQUEST, handleRequestDeleteInstance);
}


export function* handleFetchPendingInstances(action) {
  try {
    const response = yield call(getContentOnProcess);
    yield put({
      type: GET_INSTANCE_PENDING.SUCCESS,
      response,
    });
  } catch (error) {
    displayNotification('error',null,error?.data?.msg)
    yield put({type: GET_INSTANCE_PENDING.FAILURE, error});
  }
}

export function* watchFetchPendingInstances() {
  yield takeEvery(GET_INSTANCE_PENDING.REQUEST, handleFetchPendingInstances);
 }



 export function* handleFetchRejectedInstances(action) {
  try {
    const response = yield call(getContentOnRejected);
    yield put({
      type: GET_INSTANCE_REJECTED.SUCCESS,
      response,
    });
  } catch (error) {
    displayNotification('error',null,error?.data?.msg)
    yield put({type: GET_INSTANCE_REJECTED.FAILURE, error});
  }
}

export function* watchFetchFetchRejectedInstances() {
  yield takeEvery(GET_INSTANCE_REJECTED.REQUEST, handleFetchRejectedInstances);
 }



 export function* handleFetchApprovedInstances(action) {
  try {
    const response = yield call(getContentOnApproved);
    yield put({
      type: GET_INSTANCE_APPROVED.SUCCESS,
      response,
    });
  } catch (error) {
    displayNotification('error',null,error?.data?.msg)
    yield put({type: GET_INSTANCE_APPROVED.FAILURE, error});
  }
}

export function* watchFetchFetchApprovedInstances() {
  yield takeEvery(GET_INSTANCE_APPROVED.REQUEST, handleFetchApprovedInstances);
 }


 export function* handleFetchComments(action) {
  try {
    const response = yield call(getComments,action?.params);
    yield put({
      type: GET_COMMENTS.SUCCESS,
      response,
    });
  } catch (error) {
    displayNotification('error',null,error?.data?.msg)
    yield put({type: GET_COMMENTS.FAILURE, error});
  }
}

export function* watchFetchComments() {
  yield takeEvery(GET_COMMENTS.REQUEST, handleFetchComments);
 }


 export function* handlePostComments(action) {
  try {
    const {body} = action
    const response = yield call(postRequestComment,body);
    yield put({
      type: POST_COMMENTS.SUCCESS,
      response,
    });
    var params = {};
    if(body?.contentID){
      params.contentID = body.contentID;
    }else{
      params.instanceID = body.instanceID;
    }
    yield put({
      type: GET_COMMENTS.REQUEST,
      params,
    });
  } catch (error) {
    displayNotification('error',null,error?.data?.msg)
    yield put({type: POST_COMMENTS.FAILURE, error});
  }
}

export function* watchPostComments() {
  yield takeEvery(POST_COMMENTS.REQUEST, handlePostComments);
 }
