import { all } from "redux-saga/effects";
import {
  watchFetchComments,
  watchFetchFetchApprovedInstances,
  watchFetchFetchRejectedInstances,
  watchFetchPendingInstances,
  watchFRequestUploadContent,
  watchPostComments,
  watchRequestDeleteInstance,
  watchRequestSubmitInstance,
} from "./agent/saga";
import { watchRequestSignIn } from "./auth/saga";
import {
  watchFetchAllStats,
  watchFetchImages,
  watchFetchVideos,
  watchFetchDocuments,
  watchFetchInstances,
} from "./dashboard/saga";

export default function* rootSaga() {
  yield all([
    watchRequestSignIn(),
    watchFetchAllStats(),
    watchFetchInstances(),
    watchFetchVideos(),
    watchFetchImages(),
    watchFetchDocuments(),
    watchFRequestUploadContent(),
    watchRequestSubmitInstance(),
    watchRequestDeleteInstance(),
    watchFetchFetchRejectedInstances(),
    watchFetchPendingInstances(),
    watchFetchFetchApprovedInstances(),
    watchFetchComments(),
    watchPostComments()
  ]);
}
