import axios from "axios";
import { pick } from "lodash";
import {
  AZURE_MSAL_CONFIG,
  BASE_API,
  KEY_STORAGE_REFRESH_TOKEN,
  KEY_STORAGE_TOKEN,
} from "./constants";
import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { navigate } from "gatsby";

let isAlreadyFetchingAccessToken = false;
// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];

const isTokenExpiredError = (error) => {
  if (
    error?.response?.status === 403 &&
    error?.response?.data?.msg?.includes("Access token has expired")
  ) {
    return true;
  }
  return false;
};

const resetTokenAndReattemptRequest = async (error) => {
  try {
    const { response: errorResponse } = error;
    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((access_token) => {
        errorResponse.config.headers.Authorization = "Bearer " + access_token;
        resolve(axios(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
      const accounts = msalInstance.getAllAccounts();

      if (!activeAccount && accounts.length === 0) {
        /*
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
        // We can't refresh, throw the error anyway
        return Promise.reject(error);
      }
      isAlreadyFetchingAccessToken = true;
      var request = {
        scopes: ["User.Read"],
        account: activeAccount || accounts[0],
      };
      const msalInstance = new PublicClientApplication(AZURE_MSAL_CONFIG);
      msalInstance
        .acquireTokenSilent(request)
        .then((response) => {
          if (response) {
            const newToken = response?.accessToken;
            sessionStorage.setItem(KEY_STORAGE_TOKEN, newToken);
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(newToken);
          }
        })
        .catch(async (error) => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalInstance.acquireTokenPopup(request);
          }
        })
        .catch((error) => {
          // remove storage token if unauthorized
          sessionStorage.clear();
          navigate("/app/dashboard", { replace: true });
        });
    }
    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
};

const onAccessTokenFetched = (access_token) => {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach((callback) => callback(access_token));
  subscribers = [];
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

const instance = axios.create({
  method: "get",
  headers: {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
  },
  timeout: 1000 * 10,
  validateStatus: (status) => {
    const isValid = status >= 200 && status < 400; // default
    return isValid;
  },
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  //Do something before request is sent
  const headers = {
    ...config.headers.common,
    ...config.headers[config.method],
    ...config.headers,
  };
  ["common", "get", "post", "head", "put", "patch", "delete"].forEach(
    (header) => {
      delete headers[header];
    }
  );
  const printableLogs = `${new Date()} | Request: ${config.method.toUpperCase()} | ${
    config.url
  } | ${JSON.stringify(config.data)} | ${JSON.stringify(headers)}`;

  return config;
});

instance.interceptors.request.use(null, (error) => {
  const printableLogs = `${new Date()} | Request Error non-http: ${JSON.stringify(
    error
  )}`;
  return Promise.reject(error);
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const printableLogs = `${new Date()} | Response Error Http: ${
      error.message
    } | ${originalRequest.url} `;
    if (isTokenExpiredError(error)) {
      return resetTokenAndReattemptRequest(error);
    } else if (error?.response?.status === 401) {
      // remove storage token if unauthorized
      sessionStorage.clear();
    }
    return Promise.reject(error);
  }
);

class API {
  constructor() {
    this.BASE_URL = BASE_API;
  }

  async request(request, baseURL = this.BASE_URL, onUploadProgress = null) {
    instance.defaults.baseURL = baseURL;
    instance.defaults.headers.common.Authorization = `apiKey secret`;
    let token = sessionStorage.getItem(KEY_STORAGE_TOKEN);
    if (token) {
      const Authorization = `Bearer ${token}`;
      instance.defaults.headers.common.Authorization = Authorization;
    }
    if (request.contentType && request.contentType === "urlencoded") {
      instance.defaults.headers.common["Content-Type"] =
        "application/x-www-form-urlencoded";
    }
    if (request.contentType && request.contentType === "form-data") {
      instance.defaults.headers.common["Content-Type"] = "multipart/form-data";
    }
    instance.defaults.onUploadProgress = (event) => {
      if (onUploadProgress) {
        onUploadProgress(event);
      }
    };
    try {
      const response = await instance(request);
      const printableLogs = `${new Date()} | Response Success Http: ${
        response.status
      } | ${response.config.url}`;
      return response.data;
    } catch (error) {
      if (error.code === "ECONNABORTED") return "timeout";
      else throw pick(error?.response, ["data", "status"]);
    }
  }
}

const APIService = new API();

export default APIService;
