import APIService from "../utils/api-service";

export const uploadMedia = async (formData, onUploadProgress, isResubmit) => {

  return APIService.request({
    url: !isResubmit ? "agent/media/upload" : "agent/media/resubmit",
    data: formData,
    method: "post",
    onUploadProgress,
  });
};

export const submitToReview = async (instanceID) => {
  return APIService.request({
    url: "agent/media/review",
    data: {
      instanceID,
    },
    method: "post",
  });
};


export const getComments = async (params) => {
  return APIService.request({
    url: `agent/media/comments`,
    params,
  });
};


export const postRequestComment = async (body) => {
  return APIService.request({
    url: "agent/media/comments",
    data: body,
    method: "post",
  });
};