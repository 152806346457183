export const theme = {
  color: {
    w: "#FFFFFF",
    k: "#000000",
    p1: "#D31145",
    p2: "#ffa101",
    p3: "#554344",
    p4: "#363E3F",
    s1: "#E86487",
    s2: "#FBBF56",
    s3: "#97CB5D",
    s4: "#46D3BD",
    s5: "#22A8DA",
    s6: "#9962D2",
    t1: "#F6CCD7",
    t2: "#B8123E",
    t3: "#F9E1B8",
    t4: "#C36418",
    t5: "#C6EB9E",
    t6: "#65962E",
    b1: "#D4EDF1",
    b2: "#FAF9F3",
    b3: "#F0EEE1",
    b4: "#DBDAD4",
    b5: "#B3B1A8",
    b6: "#6F6E68",
    b8: "#302F2C",
    b9: "#292025",
    u2: "#FF5500",
    u3: "#405B70",
    u4: "#769FAB",
    c0: "#596c80",
    c2: "#00ff00",
    c3: "#ffd700",
  },
};

export const BASE_API = `https://ishare.aia.com.sg/ishare`;
export const KEY_STORAGE_TOKEN = "KEY_STORAGE_TOKEN";
export const KEY_STORAGE_REFRESH_TOKEN = "KEY_STORAGE_REFRESH_TOKEN";

export const CONTENT_TYPE = {
  IMAGE: "Image",
  VIDEO: "Video",
  DOCUMENT: "Document",
};

export const STATUS_APPROVAL = {
  Pending: 0,
  Approved: 1,
  Reject: -1,
};

export const PAGE_TYPE = {
  DASHBOARD: "dashboard",
  APPROVED: "approved",
  PENDING_APPROVAL: "pending for approval",
};

export const ICON_TYPE = {
  SHARE: "share",
  MAIL: "mail",
};

export const POSTING_TYPE = [
  { label: "VIDEO", value: "Video" },
  { label: "IMAGE", value: "Image" },
  { label: "DOCUMENT", value: "Document" },
];

// INSTANCE_PENDING: 0,
// INSTANCE_SUBMITTED: 1,
// INSTANCE_PENDING_PRIMARY_STAFF: 2,
// INSTANCE_PENDING_SECONDARY_STAFF: 3,

export const INSTANCE_STATUS = {
  UnSubmitted: 0,
  INSTANCE_SUBMITTED: 1,
  INSTANCE_PENDING_PRIMARY_STAFF: 2,
  INSTANCE_PENDING_SECONDARY_STAFF: 3,
  Rejected: 99,
  Approved: 9,
};

export const AZURE_MSAL_CONFIG = {
  auth: {
    clientId: "4924a7ba-a6a4-4240-a1d2-ef193b63283f",
    authority: `https://login.microsoftonline.com/853884de-cbbc-42b5-9576-56cac3b3be72`,
    redirectUri: `https://ishare.aia.com.sg/app`,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};

export const COSENT_IDS = [2, 3, 4, 5, 6, 9, 7];
