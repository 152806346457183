import {defineAction} from 'redux-define';
import { APP_NAME_SPACE, FAILURE, REQUEST, SUCCESS } from '../root-state';

export const DASHBOARD = defineAction('DASHBOARD', [], APP_NAME_SPACE);
export const GET_ALL_STATS = DASHBOARD.defineAction('GET_ALL_STATS',[REQUEST, SUCCESS, FAILURE],DASHBOARD);

export const GET_INSTANCE = DASHBOARD.defineAction('GET_INSTANCE',[REQUEST, SUCCESS, FAILURE],DASHBOARD);

export const GET_VIDEOS = DASHBOARD.defineAction('GET_VIDEOS',[REQUEST, SUCCESS, FAILURE],DASHBOARD);
export const GET_IMAGES = DASHBOARD.defineAction('GET_IMAGES',[REQUEST, SUCCESS, FAILURE],DASHBOARD);
export const GET_DOCUMENTS = DASHBOARD.defineAction('GET_DOCUMENTS',[REQUEST, SUCCESS, FAILURE],DASHBOARD);

export const fetchAllStats = () => {
    return {
      type: GET_ALL_STATS.REQUEST,
    };
};

export const fetchInstances = () => {
  return {
    type: GET_INSTANCE.REQUEST,
  };
};

export const fetchVideos = () => {
  return {
    type: GET_VIDEOS.REQUEST,
  };
};

export const fetchImages = () => {
  return {
    type: GET_IMAGES.REQUEST,
  };
};

export const fetchDocuments = () => {
  return {
    type: GET_DOCUMENTS.REQUEST,
  };
};