import {defineAction} from 'redux-define';
import { APP_NAME_SPACE, FAILURE, REQUEST, SUCCESS } from '../root-state';

export const AUTH = defineAction('AUTH', [], APP_NAME_SPACE);

export const SIGN = AUTH.defineAction('SIGN',[REQUEST, SUCCESS, FAILURE],AUTH);

export const requestLogin = (body) => {
    return {
      type: SIGN.REQUEST,
      body,
    };
};