import { Record } from 'immutable';
import { GET_ALL_STATS, GET_DOCUMENTS, GET_IMAGES, GET_INSTANCE, GET_VIDEOS } from './actions';

const ObjectRecord = new Record({
  isFetchStats: false,
  isFetchInstance: false,
  isFetchVideos: false,
  isFetchImages: false,
  isFetchDocuments: false,
  allStats : null,
  instances : [],
  videos : [],
  images : [],
  documents : [],
});
const initialState = new ObjectRecord();
const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STATS.REQUEST:
      return state.set('isFetchStats', true);
    case GET_ALL_STATS.SUCCESS:
      return state
        .set('allStats', action?.response?.payload)
        .set('isFetchStats', false);
    case GET_ALL_STATS.FAILURE:
      return state.set('isFetchStats', false);
    case GET_INSTANCE.REQUEST:
      return state.set('isFetchInstance', true);
    case GET_INSTANCE.SUCCESS:
      return state
        .set('instances', action?.response?.payload)
        .set('isFetchInstance', false);
    case GET_INSTANCE.FAILURE:
      return state.set('isFetchInstance', false);
    case GET_VIDEOS.REQUEST:
      return state.set('isFetchVideos', true);
    case GET_VIDEOS.SUCCESS:
      return state
        .set('videos', action?.response?.payload)
        .set('isFetchVideos', false);
    case GET_VIDEOS.FAILURE:
      return state.set('isFetchVideos', false);
    case GET_IMAGES.REQUEST:
      return state.set('isFetchImages', true);
    case GET_IMAGES.SUCCESS:
      return state
        .set('images', action?.response?.payload)
        .set('isFetchImages', false);
    case GET_IMAGES.FAILURE:
      return state.set('isFetchImages', false);
    case GET_DOCUMENTS.REQUEST:
        return state.set('isFetchDocuments', true);
    case GET_DOCUMENTS.SUCCESS:
        return state
          .set('documents', action?.response?.payload)
          .set('isFetchDocuments', false);
    case GET_DOCUMENTS.FAILURE:
        return state.set('isFetchDocuments', false);
    default:
      return state;
  }
};

export default dashboard;
