import { defineAction } from "redux-define";
import { ADD, APP_NAME_SPACE, FAILURE, REQUEST, SUCCESS } from "../root-state";

export const AGENT = defineAction("AGENT", [], APP_NAME_SPACE);
export const UPLOAD_CONTENT = AGENT.defineAction(
  "UPLOAD_CONTENT",
  [REQUEST, SUCCESS, FAILURE],
  AGENT
);
export const UPLOAD_CONTENT_PROGRESS = AGENT.defineAction(
  "UPLOAD_CONTENT_PROGRESS",
  [ADD],
  AGENT
);
export const SUBMIT_INSTANCE = AGENT.defineAction(
  "SUBMIT_INSTANCE",
  [REQUEST, SUCCESS, FAILURE],
  AGENT
);
export const DELETE_INSTANCE = AGENT.defineAction(
  "DELETE_INSTANCE",
  [REQUEST, SUCCESS, FAILURE],
  AGENT
);
export const GET_INSTANCE_PENDING = AGENT.defineAction(
  "GET_INSTANCE_PENDING",
  [REQUEST, SUCCESS, FAILURE],
  AGENT
);
export const GET_INSTANCE_REJECTED = AGENT.defineAction(
  "GET_INSTANCE_REJECTED",
  [REQUEST, SUCCESS, FAILURE],
  AGENT
);
export const GET_INSTANCE_APPROVED = AGENT.defineAction(
  "GET_INSTANCE_APPROVED",
  [REQUEST, SUCCESS, FAILURE],
  AGENT
);
export const RESET_STATE_AGENT = AGENT.defineAction(
  "RESET_STATE_AGENT",
  [REQUEST],
  AGENT
);
export const GET_COMMENTS = AGENT.defineAction(
  "GET_COMMENTS",
  [REQUEST, SUCCESS, FAILURE],
  AGENT
);

export const POST_COMMENTS = AGENT.defineAction(
  "POST_COMMENTS",
  [REQUEST, SUCCESS, FAILURE],
  AGENT
);

export const requestUploadContent = (formData, isResubmit) => {
  return {
    type: UPLOAD_CONTENT.REQUEST,
    formData,
    isResubmit,
  };
};

export const uploadFilesProgress = (progress) => {
  return {
    type: UPLOAD_CONTENT_PROGRESS.ADD,
    progress,
  };
};

export const requestSubmitInstance = (instanceID) => {
  return {
    type: SUBMIT_INSTANCE.REQUEST,
    instanceID,
  };
};

export const requestDeleteInstance = (id) => {
  return {
    type: DELETE_INSTANCE.REQUEST,
    id,
  };
};

export const fetchPendingInstances = () => {
  return {
    type: GET_INSTANCE_PENDING.REQUEST,
  };
};

export const fetchApprovedInstances = () => {
  return {
    type: GET_INSTANCE_APPROVED.REQUEST,
  };
};

export const fetchRejectedInstances = () => {
  return {
    type: GET_INSTANCE_REJECTED.REQUEST,
  };
};

export const resetStateAgent = () => {
  return {
    type: RESET_STATE_AGENT.REQUEST,
  };
};

export const fetchComments = (params) => {
  return {
    type: GET_COMMENTS.REQUEST,
    params,
  };
};

export const postComments = (body) => {
  return {
    type: POST_COMMENTS.REQUEST,
    body,
  };
};
