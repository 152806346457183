import { combineReducers } from 'redux';
import auth from './auth/reducer';
import dashboard from './dashboard/reducer';
import agent from './agent/reducer';

const rootReducer = {
    auth,
    dashboard,
    agent
};

export default combineReducers(rootReducer);
