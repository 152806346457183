import APIService from "../utils/api-service";

export const getAllStats = async () => {
  return APIService.request({
    url: 'agent/dashboard/all_stats',
  });
};



export const getContentList = async () => {
  return APIService.request({
    url: `agent/media/instance/pending`,
  });
};

export const getContentOnProcess = async () => {
  return APIService.request({
    url: `agent/media/instance/on_process`,
  });
};

export const getContentOnRejected = async () => {
  return APIService.request({
    url: `agent/media/instance/rejected`,
  });
};

export const getContentOnApproved = async () => {
  return APIService.request({
    url: `agent/media/instance/approved`,
  });
};




export const deleteInstance = async (id) => {
  return APIService.request({
    url: `agent/media/instance`,
    method : 'delete',
    params : {
      instance_id : id
    }
  });
};


