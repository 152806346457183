import {defineAction} from 'redux-define';

export const APP_NAME_SPACE = defineAction('@iShare');

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const SHOW = 'SHOW';
export const CLOSE = 'CLOSE';
export const CLEAR = 'CLEAR';
export const UPDATE = 'UPDATE';
export const ADD = 'ADD';

export const TOKEN = 'TOKEN';
export const USER = 'USER';

export const STORAGE = defineAction('STORAGE', [USER, TOKEN], APP_NAME_SPACE);
