import { call, put, takeEvery } from "redux-saga/effects";
import { sign } from "../../services/auth";
import {
  KEY_STORAGE_REFRESH_TOKEN,
  KEY_STORAGE_TOKEN,
} from "../../utils/constants";
import { displayNotification } from "../../utils/notificationHandler";
import { SIGN } from "./actions";

export function* handleRequestSignIn(action) {
  try {
    const { body } = action;
    const response = yield call(sign, body);
    yield put({
      type: SIGN.SUCCESS,
      response
    });
  } catch (error) {
    displayNotification("error", null, error?.data?.msg);
    yield put({ type: SIGN.FAILURE, error });
  }
}

export function* watchRequestSignIn() {
  yield takeEvery(SIGN.REQUEST, handleRequestSignIn);
}
