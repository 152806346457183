import { call, put, takeEvery } from "redux-saga/effects";
import {
  getAllStats,
  getContentList
} from "../../services/dashboard";
import { CONTENT_TYPE } from "../../utils/constants";
import { displayNotification } from "../../utils/notificationHandler";
import {
  GET_ALL_STATS,
  GET_DOCUMENTS,
  GET_IMAGES,
  GET_INSTANCE,
  GET_VIDEOS
} from "./actions";

export function* handleFetchAllStats(action) {
  try {
    const response = yield call(getAllStats);
    yield put({
      type: GET_ALL_STATS.SUCCESS,
      response,
    });
  } catch (error) {
    displayNotification("error", null, error?.data?.msg);
    yield put({ type: GET_ALL_STATS.FAILURE, error });
  }
}

export function* watchFetchAllStats() {
  yield takeEvery(GET_ALL_STATS.REQUEST, handleFetchAllStats);
}

export function* handleFetchInstances(action) {
  try {
    const response = yield call(getContentList);
    yield put({
      type: GET_INSTANCE.SUCCESS,
      response,
    });
  } catch (error) {
    displayNotification("error", null, error?.data?.msg);
    yield put({ type: GET_INSTANCE.FAILURE, error });
  }
}

export function* watchFetchInstances() {
  yield takeEvery(GET_INSTANCE.REQUEST, handleFetchInstances);
}

export function* handleFetchVideos(action) {
  try {
    const response = yield call(getContentList, CONTENT_TYPE.VIDEO);
    yield put({
      type: GET_VIDEOS.SUCCESS,
      response,
    });
  } catch (error) {
    displayNotification("error", null, error?.data?.msg);
    yield put({ type: GET_VIDEOS.FAILURE, error });
  }
}

export function* watchFetchVideos() {
  yield takeEvery(GET_VIDEOS.REQUEST, handleFetchVideos);
}

export function* handleFetchImages(action) {
  try {
    const response = yield call(getContentList, CONTENT_TYPE.IMAGE);
    yield put({
      type: GET_IMAGES.SUCCESS,
      response,
    });
  } catch (error) {
    displayNotification("error", null, error?.data?.msg);
    yield put({ type: GET_IMAGES.FAILURE, error });
  }
}

export function* watchFetchImages() {
  yield takeEvery(GET_IMAGES.REQUEST, handleFetchImages);
}

export function* handleFetchDocuments(action) {
  try {
    const response = yield call(getContentList, CONTENT_TYPE.DOCUMENT);
    yield put({
      type: GET_DOCUMENTS.SUCCESS,
      response,
    });
  } catch (error) {
    displayNotification("error", null, error?.data?.msg);
    yield put({ type: GET_DOCUMENTS.FAILURE, error });
  }
}

export function* watchFetchDocuments() {
  yield takeEvery(GET_DOCUMENTS.REQUEST, handleFetchDocuments);
}
