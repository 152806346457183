import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.css';
const GlobalStyle = createGlobalStyle`
  body p, ul, ol {
    font-family: "AIARegular", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 0px;
  }
`;

export default GlobalStyle;